.card {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 2px;
  filter: grayscale(80%);
  background: #e6c233;

}

.imageContainer {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative
}

.overlay {
  width: 100%;
  height: 125px;
  background-color: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.artistInfoContainer {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  padding: 10px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(0, -30px);

  .pfpContainer {
    width: 80px;
    height: 80px;
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    background: #e6c233;
  }

  .pfp {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  .alias {
    margin-top: 10px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .handle {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 768px) {
  .card {
    background: radial-gradient(ellipse farthest-corner at right bottom, #e6c233 0%, #e6b82c 8%, #8f6f25 30%, #7a622b 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #baa45a 0%, #e6e6a4 8%, #baa45a 25%, #53491c 62.5%, #53491c 100%);
  }
  .overlay {
    height: 50%;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 30%, rgba(69,69,69,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 30%, rgba(69,69,69,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 30%, rgba(69,69,69,0) 100%);
  }
  .artistInfoContainer {
    .pfpContainer {
      width: 100px;
      height: 100px;
      background: radial-gradient(ellipse farthest-corner at right bottom, #e6c233 0%, #e6b82c 8%, #8f6f25 30%, #7a622b 40%, transparent 80%),
                  radial-gradient(ellipse farthest-corner at left top, #baa45a 0%, #e6e6a4 8%, #baa45a 25%, #53491c 62.5%, #53491c 100%);
    }
  }
}
