/* Mobile */
body {
  background-color: rgb(30, 30, 30);
}
.appContainer {
  width: 100%;
  height: 100svh;
  min-height: 100%;
  color: white;
  background-color: rgb(30, 30, 30);
  box-sizing: border-box;
  overflow: hidden;
}

.pageContainer {
  width: 100%;
  height: 95svh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Desktop */
@media only screen and (min-width: 768px) {
  .appContainer {
    width: 100%;
  }
}