.headerContainer  {
  width: 100%;
  height: 5svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  h1 {
    margin: 0;
    padding: 0;
    font-size: 0.7rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #f8d46b;
  }

  .linksContainer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .headerContainer  {
    h1 {
      font-size: 1rem;
    }
  }
}